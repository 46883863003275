import { Link } from 'gatsby'
import React from 'react'
import Marked from 'react-markdown'
import './meet-us.scss'
import loadable from '@loadable/component'
import { isEqual } from 'lodash'
const Fade = loadable(() => import('../transition/Fade'))

class MeetUs extends React.Component {
  state = {
    empOfMonth: this.props.empOfMonthDetail,
    teamListData: []
  }

  componentWillMount = () => {
    let teams = []
    this.props.teamListEdges.map(project => {
      return teams.push(project)
    })
    this.setState({
      teamListData: teams
    })
  }
  
  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(this.props.teamListEdges, nextProps.teamListEdges) ? true : false
  }

  formatArray = () => {
    let formattedArray = []
    let doubleQuadCounter = 1
    let quadQuadCounter = 0
    let arrayOne = []
    let arrayTwo = []
    while (this.state.teamListData.length > 0) {
      if (doubleQuadCounter > 0) {
        arrayOne = this.state.teamListData.splice(0, 2)
        arrayTwo = this.state.teamListData.splice(0, 4)
        formattedArray.push([arrayOne, arrayTwo])
        quadQuadCounter = 1
      }
      if (quadQuadCounter > 0) {
        arrayOne = this.state.teamListData.splice(0, 4)
        arrayTwo = this.state.teamListData.splice(0, 4)
        formattedArray.push([arrayOne, arrayTwo])
        doubleQuadCounter = 1
      }
    }
    return formattedArray
  }

  render() {
    const renderTeam = teamArray => {
      return (
        <div className="section-content">
          {teamArray.map((subArray, i) => (
            <div
              className="img-main-container d-flex justify-content-between flex-wrap"
              key={i}
            >
              {subArray.map(({ node }, k) => (
                <Link className="img-link" to={node.fields.slug} key={k}>
                  <Fade timeout={500}>
                    <div className="img-container">
                      <img
                        srcSet={node.frontmatter.ourTeamImage}
                        className="lazyload meet-us-img"
                        alt={node.frontmatter.fullName}
                      />
                    </div>  
                  </Fade>
                  <div className="img-dec">
                    <p>{node.frontmatter.fullName}</p>
                    <p>{node.frontmatter.position}</p>
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      )
    }
    return (
      <div className="mee-us-parent-container">
        {this.formatArray().map((teamArray, index) => {
          if (index === 0) {
            return (
              <div className="first-section-container" key={index} id="common-px">
                <div className="title-container">
                  <h3>Meet Us</h3>
                </div>
                <div className="first-section-content">
                  {renderTeam(teamArray)}
                </div>
              </div>
            )
          } else if (index === 1) {
            // if (this.state.empOfMonth) {
              
            // }
            return (
                <div className="second-section-container" key={index}>
                  {
                    <div className="second-part" id="common-px">
                      {renderTeam(teamArray)}
                      <div className="bg-angle d-none d-lg-block">
                        <img
                          loading="lazy"
                          src="https://res.cloudinary.com/dhuii7xg2/image/upload/f_auto,fl_progressive,w_auto,q_auto/v1614325932/Group_40_ftwpsy.png"
                          className="img-fluid"
                          alt="Group_40_ftwpsy"
                        />
                      </div>

                      <div className="first-quote quote-container text-center text-lg-left">
                        <div className="text-center text-lg-left">
                          <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.2034 27.654C17.7257 27.2285 16.2479 27.0126 14.8104 27.0126C12.5906 27.0126 10.7383 27.5199 9.30205 28.1411C10.6867 23.0721 14.013 14.3256 20.6391 13.3406C21.2527 13.2493 21.7556 12.8056 21.923 12.2084L23.3712 7.02856C23.4933 6.59051 23.4209 6.12162 23.1711 5.74148C22.9212 5.36133 22.5196 5.10769 22.0696 5.04601C21.5806 4.9793 21.0821 4.94531 20.5881 4.94531C12.6346 4.94531 4.75793 13.2468 1.43418 25.1333C-0.516897 32.1068 -1.089 42.591 3.71694 49.1901C6.40628 52.8826 10.3298 54.8545 15.3787 55.0515C15.3995 55.0521 15.4196 55.0527 15.4404 55.0527C21.67 55.0527 27.194 50.8573 28.8745 44.8511C29.8783 41.2605 29.4246 37.4949 27.5956 34.2454C25.7861 31.0325 22.806 28.6905 19.2034 27.654Z"
                              fill="#222222"
                            />
                            <path
                              d="M58.1985 34.2461C56.3891 31.0325 53.409 28.6905 49.8064 27.654C48.3286 27.2285 46.8508 27.0126 45.414 27.0126C43.1941 27.0126 41.3412 27.5199 39.905 28.1411C41.2896 23.0721 44.6159 14.3256 51.2426 13.3406C51.8563 13.2493 52.3585 12.8056 52.5266 12.2084L53.9748 7.02856C54.0969 6.59051 54.0245 6.12162 53.7746 5.74148C53.5254 5.36133 53.1239 5.10769 52.6732 5.04601C52.1848 4.9793 51.6864 4.94531 51.1917 4.94531C43.2382 4.94531 35.3615 13.2468 32.0371 25.1333C30.0867 32.1068 29.5146 42.591 34.3211 49.1913C37.0099 52.8833 40.934 54.8557 45.9823 55.0521C46.0031 55.0527 46.0232 55.0534 46.0446 55.0534C52.2736 55.0534 57.7983 50.8579 59.4787 44.8517C60.4813 41.2611 60.0269 37.4949 58.1985 34.2461Z"
                              fill="#222222"
                            />
                          </svg>
                        </div>

                        <Marked
                          className={`Content`}
                          source={this.props.quotes.quote_one}
                        />
                      </div>
                      <div className="second-quote quote-container text-center text-lg-left">
                        <div className="text-center text-lg-left">
                          <svg
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.2034 27.654C17.7257 27.2285 16.2479 27.0126 14.8104 27.0126C12.5906 27.0126 10.7383 27.5199 9.30205 28.1411C10.6867 23.0721 14.013 14.3256 20.6391 13.3406C21.2527 13.2493 21.7556 12.8056 21.923 12.2084L23.3712 7.02856C23.4933 6.59051 23.4209 6.12162 23.1711 5.74148C22.9212 5.36133 22.5196 5.10769 22.0696 5.04601C21.5806 4.9793 21.0821 4.94531 20.5881 4.94531C12.6346 4.94531 4.75793 13.2468 1.43418 25.1333C-0.516897 32.1068 -1.089 42.591 3.71694 49.1901C6.40628 52.8826 10.3298 54.8545 15.3787 55.0515C15.3995 55.0521 15.4196 55.0527 15.4404 55.0527C21.67 55.0527 27.194 50.8573 28.8745 44.8511C29.8783 41.2605 29.4246 37.4949 27.5956 34.2454C25.7861 31.0325 22.806 28.6905 19.2034 27.654Z"
                              fill="#222222"
                            />
                            <path
                              d="M58.1985 34.2461C56.3891 31.0325 53.409 28.6905 49.8064 27.654C48.3286 27.2285 46.8508 27.0126 45.414 27.0126C43.1941 27.0126 41.3412 27.5199 39.905 28.1411C41.2896 23.0721 44.6159 14.3256 51.2426 13.3406C51.8563 13.2493 52.3585 12.8056 52.5266 12.2084L53.9748 7.02856C54.0969 6.59051 54.0245 6.12162 53.7746 5.74148C53.5254 5.36133 53.1239 5.10769 52.6732 5.04601C52.1848 4.9793 51.6864 4.94531 51.1917 4.94531C43.2382 4.94531 35.3615 13.2468 32.0371 25.1333C30.0867 32.1068 29.5146 42.591 34.3211 49.1913C37.0099 52.8833 40.934 54.8557 45.9823 55.0521C46.0031 55.0527 46.0232 55.0534 46.0446 55.0534C52.2736 55.0534 57.7983 50.8579 59.4787 44.8517C60.4813 41.2611 60.0269 37.4949 58.1985 34.2461Z"
                              fill="#222222"
                            />
                          </svg>
                        </div>

                        <Marked
                          className={`Content`}
                          source={this.props.quotes.quote_two}
                        />
                      </div>
                    </div>
                  }
                </div>
              )
          } else {
            return (
              <div
                id="common-px"
                className={
                  index === 2
                    ? `third-section-container third-section-container-${index}`
                    : 'third-section-container'
                }
              >
                <div key={index}>{renderTeam(teamArray)}</div>
              </div>
            )
          }
        })}

        <div className="bg-text-container">
          <img
            srcSet="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325549/WORK_1_bctdvy.png"
            alt="work"
            className="lazyload img-fluid"
          />
        </div>
      </div>
    )
  }
}

export default MeetUs
